.H1Semi-BoldCentreWarningBoldPressed {
  font-family: Kanit;
  font-size: 96px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-warning-base);
}

.H1BoldCentreSecondaryActive {
  font-family: Kanit;
  font-size: 96px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-secondary-200);
}

.H1Semi-BoldLeftPrimaryPressed {
  font-family: Kanit;
  font-size: 96px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-primary-variant-dark);
}

.H1Semi-BoldLeftErrorDisabled {
  font-family: Kanit;
  font-size: 96px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-error-base);
}

.H1Semi-BoldCentreWhite {
  font-family: Kanit;
  font-size: 96px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-050);
}

.H1Semi-BoldCentreSuccessActive {
  font-family: Kanit;
  font-size: 96px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-success-base);
}

.H1Semi-BoldCentrePrimaryActive {
  font-family: Kanit;
  font-size: 96px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-900);
}

.H1Semi-BoldCentreErrorDisabled {
  font-family: Kanit;
  font-size: 96px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-error-base);
}

.H1Semi-BoldCentreBaseDisabled {
  font-family: Kanit;
  font-size: 96px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-500);
}

.H1RegularRightSecondaryPressed {
  font-family: Kanit;
  font-size: 96px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-secondary-variant-dark);
}

.H1RegularRightSecondaryActive {
  font-family: Kanit;
  font-size: 96px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-secondary-200);
}

.H1RegularLeftBaseDisabled {
  font-family: Kanit;
  font-size: 96px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-base-500);
}

.H1RegularCentreSecondaryPressed {
  font-family: Kanit;
  font-size: 96px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-secondary-variant-dark);
}

.H1RegularCentrePrimaryActive {
  font-family: Kanit;
  font-size: 96px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-900);
}

.H1RegularCentreBaseDisabled {
  font-family: Kanit;
  font-size: 96px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-500);
}

.H2BoldRightBlack {
  font-family: Kanit;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.H2BoldCentreWhite {
  font-family: Kanit;
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-050);
}

.H2Semi-BoldLeftSuccessActive {
  font-family: Kanit;
  font-size: 60px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-success-base);
}

.H2Semi-BoldLeftPrimaryActive {
  font-family: Kanit;
  font-size: 60px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-primary-900);
}

.H2Semi-BoldCentreWarningPressed {
  font-family: Kanit;
  font-size: 60px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-warning-base);
}

.H2Semi-BoldCentreBasePressed {
  font-family: Kanit;
  font-size: 60px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-900);
}

.H2Semi-BoldCentreBaseActive {
  font-family: Kanit;
  font-size: 60px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-700);
}

.H2RegularRightPrimaryPressed {
  font-family: Kanit;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-primary-variant-dark);
}

.H2RegularRightPrimaryActive {
  font-family: Kanit;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-primary-900);
}

.H2RegularLeftSecondaryDisabled {
  font-family: Kanit;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-secondary-variant-light);
}

.H2RegularLeftBaseActive {
  font-family: Kanit;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-base-700);
}

.H2RegularCentreSecondaryDisabled {
  font-family: Kanit;
  font-size: 60px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-secondary-variant-light);
}

.H3BoldRightWarningPressed {
  font-family: Kanit;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-warning-base);
}

.H3Semi-BoldRightWhite {
  font-family: Kanit;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-base-050);
}

.H3Semi-BoldLeftBlack {
  font-family: Kanit;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.H3Semi-BoldLeftBaseActive {
  font-family: Kanit;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-base-700);
}

.H3Semi-BoldCentreSuccessActive {
  font-family: Kanit;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-success-base);
}

.H4BoldRightErrorDisabled {
  font-family: Kanit;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-error-base);
}

.H4BoldLeftSecondaryActive {
  font-family: Kanit;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-secondary-200);
}

.H4BoldCentreWarningPressed {
  font-family: Kanit;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-warning-base);
}

.H4BoldCentreSecondaryDisabled {
  font-family: Kanit;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-secondary-variant-light);
}

.H4Semi-BoldLeftPrimaryPressed {
  font-family: Kanit;
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-primary-variant-dark);
}

.H4Semi-BoldCentreWhite {
  font-family: Kanit;
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-050);
}

.H4RegularRightPrimaryDisabled {
  font-family: Kanit;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-primary-variant-light);
}

.H4RegularCentrePrimaryActive {
  font-family: Kanit;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-900);
}

.H4RegularCentreBlack {
  font-family: Kanit;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.H5BoldRightBasePressed {
  font-family: Kanit;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-base-900);
}

.H5BoldLeftSecondaryPressed {
  font-family: Kanit;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-secondary-variant-dark);
}

.H5BoldCentrePrimaryActive {
  font-family: Kanit;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-900);
}

.H5Semi-BoldRightBlack {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.H5Semi-BoldLeftSecondaryPressed {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-secondary-variant-dark);
}

.H5Semi-BoldCentreWhite {
  font-family: Kanit;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-050);
}

.H5RegularRightPrimaryPressed {
  font-family: Kanit;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-primary-variant-dark);
}

.H6BoldCentreErrorDisabled {
  font-family: Kanit;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-error-base);
}

.H6RegularRightPrimaryPressed {
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-primary-variant-dark);
}

.H6RegularLeftWhite {
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-base-050);
}

.H6RegularCentreWarningPressed {
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-warning-base);
}

.H6RegularCentrePrimaryPressed {
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-variant-dark);
}

.Subtitle-2RegularCentreWarningPressed {
  font-family: Kanit;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-warning-base);
}

.Subtitle-1BoldRightSuccessActive {
  font-family: Kanit;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-success-base);
}

.Subtitle-1BoldLeftWarningPressed {
  font-family: Kanit;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-warning-base);
}

.Body-1BoldRightSuccessActive {
  font-family: Kanit;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-success-base);
}

.Body-1BoldRightSecondaryPressed {
  font-family: Kanit;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-secondary-variant-dark);
}

.Body-1BoldRightBaseDisabled {
  font-family: Kanit;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-base-500);
}

.Subtitle-1Semi-BoldRightBlack {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.Subtitle-1Semi-BoldCentrePrimaryPressed {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-variant-dark);
}

.Subtitle-1Semi-BoldCentreErrorDisabled {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-error-base);
}

.Subtitle-1Semi-BoldCentreBasePressed {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-900);
}

.Body-1Semi-BoldRightPrimaryDisabled {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-primary-variant-light);
}

.Body-1Semi-BoldCentreSecondaryPressed {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-secondary-variant-dark);
}

.Body-1Semi-BoldCentrePrimaryPressed {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-variant-dark);
}

.Subtitle-1RegularRightSuccessActive {
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-success-base);
}

.Body-1RegularRightSecondaryPressed {
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-secondary-variant-dark);
}

.Body-1RegularRightSecondaryDisabled {
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-secondary-variant-light);
}

.Body-1RegularRightSecondaryActive {
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-secondary-200);
}

.Body-1RegularLeftPrimaryDisabled {
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-primary-variant-light);
}

.Body-1RegularLeftBaseActive {
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-base-700);
}

.Subtitle-2BoldRightSecondaryDisabled {
  font-family: Kanit;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-secondary-variant-light);
}

.Subtitle-2BoldRightBlack {
  font-family: Kanit;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.Subtitle-2BoldCentreWhite {
  font-family: Kanit;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-050);
}

.Subtitle-2BoldCentreBaseActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-700);
}

.ButtonBoldRightErrorDisabled {
  font-family: Kanit;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-error-base);
}

.Body-2BoldRightWarningPressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-warning-base);
}

.Body-2BoldRightBasePressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-base-900);
}

.Body-2BoldCentrePrimaryActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-900);
}

.Subtitle-2Semi-BoldRightPrimaryDisabled {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-primary-variant-light);
}

.Subtitle-2Semi-BoldRightBasePressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-base-900);
}

.Subtitle-2Semi-BoldLeftPrimaryPressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-primary-variant-dark);
}

.Subtitle-2Semi-BoldLeftBlack {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.Subtitle-2Semi-BoldCentreSecondaryActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-secondary-200);
}

.ButtonSemi-BoldRightSuccessActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-success-base);
}

.ButtonSemi-BoldRightSecondaryPressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-secondary-variant-dark);
}

.ButtonSemi-BoldRightPrimaryDisabled {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-primary-variant-light);
}

.ButtonSemi-BoldLeftSecondaryDisabled {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-secondary-variant-light);
}

.ButtonSemi-BoldLeftPrimaryActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-primary-900);
}

.ButtonSemi-BoldLeftBaseDisabled {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-base-500);
}

.ButtonSemi-BoldCentreWhite {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-050);
}

.ButtonSemi-BoldCentrePrimaryPressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-variant-dark);
}

.Body-2Semi-BoldRightSecondaryActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-secondary-200);
}

.Body-2Semi-BoldLeftSecondaryPressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-secondary-variant-dark);
}

.Body-2Semi-BoldCentrePrimaryPressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-variant-dark);
}

.Body-2Semi-BoldCentreBaseDisabled {
  font-family: Kanit;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-500);
}

.Subtitle-2RegularRightWhite {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-base-050);
}

.Subtitle-2RegularRightSecondaryActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-secondary-200);
}

.Subtitle-2RegularRightErrorDisabled {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-error-base);
}

.Subtitle-2RegularLeftBlack {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.ButtonRegularRightSuccessActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-success-base);
}

.ButtonRegularLeftPrimaryPressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-primary-variant-dark);
}

.ButtonRegularLeftBaseActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-base-700);
}

.ButtonRegularCentreSecondaryPressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-secondary-variant-dark);
}

.ButtonRegularCentreSecondaryActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-secondary-200);
}

.Body-2RegularRightWhite {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-base-050);
}

.Body-2RegularRightBlack {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000;
}

.Body-2RegularLeftPrimaryPressed {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-primary-variant-dark);
}

.Body-2RegularLeftBlack {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
}

.Body-2RegularLeftBaseDisabled {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-base-500);
}

.Body-2RegularCentrePrimaryDisabled {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-variant-light);
}

.Body-2RegularCentreBaseActive {
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-700);
}

.CaptionBoldLeftSecondaryPressed {
  font-family: Kanit;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-secondary-variant-dark);
}

.CaptionBoldCentreSuccessActive {
  font-family: Kanit;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-success-base);
}

.CaptionBoldCentreSecondaryPressed {
  font-family: Kanit;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-secondary-variant-dark);
}

.CaptionBoldCentrePrimaryDisabled {
  font-family: Kanit;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-variant-light);
}

.CaptionBoldCentrePrimaryActive {
  font-family: Kanit;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-900);
}

.CaptionBoldCentreBasePressed {
  font-family: Kanit;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-base-900);
}

.CaptionSemi-BoldRightBasePressed {
  font-family: Kanit;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-base-900);
}

.CaptionSemi-BoldLeftSecondaryActive {
  font-family: Kanit;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-secondary-200);
}

.CaptionSemi-BoldCentrePrimaryActive {
  font-family: Kanit;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-primary-900);
}

.CaptionRegularLeftSecondaryDisabled {
  font-family: Kanit;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-secondary-variant-light);
}

.CaptionRegularLeftBaseActive {
  font-family: Kanit;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-base-700);
}

.OverlineBoldRightPrimaryActive {
  font-family: Kanit;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-primary-900);
}

.OverlineSemi-BoldRightBaseActive {
  font-family: Kanit;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--fill-base-700);
}

.OverlineSemi-BoldLeftPrimaryDisabled {
  font-family: Kanit;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--fill-primary-variant-light);
}

.OverlineSemi-BoldCentreSuccessActive {
  font-family: Kanit;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-success-base);
}

.OverlineRegularCentreSecondaryDisabled {
  font-family: Kanit;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--fill-secondary-variant-light);
}